import { Field, Label, Select, Dialog, DialogContent, Option, DialogTrigger } from '@troon/ui';
import { For, createSignal } from 'solid-js';
import { AddCard } from '../partials/add-card';
import { cardBrandToString } from '../modules/credit-cards';
import type { CreditCard } from '../graphql';

type Props = {
	cards: Array<CreditCard>;
	name?: string;
	onSelect?: (card: CreditCard) => void;
};

export function CreditCardSelector(props: Props) {
	const [selected, setSelected] = createSignal<string | undefined>(props.cards[0]?.id);
	const [open, setOpen] = createSignal(false);

	return (
		<div class="flex flex-row items-end gap-x-4">
			<Field name="card" class="grow">
				<div class="sr-only">
					<Label>Choose card</Label>
				</div>
				<Select
					name={props.name ?? 'card'}
					onselect={(event) => {
						const card = props.cards.find((card) => card.id === event.currentTarget.value)!;
						setSelected(card.id);
						props.onSelect && props.onSelect(card);
					}}
				>
					<For
						each={props.cards}
						fallback={
							<Option disabled selected>
								No cards
							</Option>
						}
					>
						{(card) => (
							<Option value={card.id} selected={selected() === card.id}>
								{cardBrandToString[card.brand]} ending in {card.lastFour}
							</Option>
						)}
					</For>
				</Select>
			</Field>

			<div class="shrink grow-0">
				<Dialog key="add-payment-method" open={open()} onOpenChange={setOpen}>
					<DialogTrigger appearance="transparent">Add card</DialogTrigger>
					<DialogContent header="Add payment method" headerLevel="h2">
						<div class="min-w-64">
							<AddCard
								onCancel={() => setOpen(false)}
								onSuccess={() => {
									setOpen(false);
								}}
							/>
						</div>
					</DialogContent>
				</Dialog>
			</div>
		</div>
	);
}
